import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useWindowDimensions, View, Platform } from "react-native";
import { Avatar, Box, Text, Image, Heading, Center, VStack, HStack, Stack, Icon, Pressable, ScrollView, useTheme } from 'native-base';
import { LineChart } from "react-native-chart-kit";
import { VictoryChart, VictoryTheme, VictoryVoronoiContainer, VictoryArea, VictoryScatter, VictoryAxis } from "victory-native";
import { Svg, Line, Circle, Rect, Text as SVGText, ForeignObject } from "react-native-svg";
import { Octicons, Feather } from '@expo/vector-icons';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAppDispatch, useAppSelector } from 'src/ducks/useful-hooks';
import { incrementCount, decrementCount } from 'src/ducks/user-slice';
import { HomeStackParams } from 'src/navigation/home-stack';
import { useLazySignOutQuery } from 'src/services';
import { Card } from 'src/components/card';
import Button from 'src/components/button';
import { validateYupSchema } from 'formik';
import moment from 'moment-timezone';

moment.tz.setDefault("America/New_York");

/**
 * Stack Navigation Prop for accessing screen navigation prop
 */
type HomeScreenProps = StackNavigationProp<HomeStackParams, 'Home'>;

/**
 * Home screen, will display basic data to the user
 *
 * @return {*}
 */
export const HomeScreen: React.FC<{}> = () => {
    // hooks
    const navigation = useNavigation<HomeScreenProps>();
		const [mainColumnWidth, setMainColumnWidth] = useState(0);
		const [chartData, setChartData] = useState([20, 45, 28, 80, 99, 43]);
		const [lastActiveTooltip, setLastActiveTooltip] = useState({});
		const [portfolioChartTimeFrame, setPortfolioChartTimeFrame] = useState(1);
		const portfolioChartTimeFrames = [
			{value: 1, label: '1M'}, 
			{value: 3, label: '3M'},
			{value: 6, label: '6M'},
			{value: 12, label: '1Y'}
		];
		const dimensions = useWindowDimensions();
  	const isMobile = dimensions.width < 1024;
		const { colors } = useTheme();

    // redux handlers
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

		const portfolioChartData = [
			{ x: new Date(2023, 11, 1), y: 16456.56 },
			{ x: new Date(2023, 11, 2), y: 20456.56 },
			{ x: new Date(2023, 11, 3), y: 17456.56 },
			{ x: new Date(2023, 11, 4), y: 24456.56 },
			{ x: new Date(2023, 11, 5), y: 27456.56 },
			{ x: new Date(2023, 11, 6), y: 24456.23 },
			{ x: new Date(2023, 11, 7), y: 17342.65 },
			{ x: new Date(2023, 11, 8), y: 23456.23 },
			{ x: new Date(2023, 11, 9), y: 22456.23 },
			{ x: new Date(2023, 11, 10), y: 21256.23 },
			{ x: new Date(2023, 11, 11), y: 20356.23 },
			{ x: new Date(2023, 11, 12), y: 20456.67 },
			{ x: new Date(2023, 11, 13), y: 20756.23 },
			{ x: new Date(2023, 11, 14), y: 20999.23 },
			{ x: new Date(2023, 11, 15), y: 20456.14 },
			{ x: new Date(2023, 11, 16), y: 18256.16 },
			{ x: new Date(2023, 11, 17), y: 17456.14 },
			{ x: new Date(2023, 11, 18), y: 22789.14 },
			{ x: new Date(2023, 11, 19), y: 21496.17 },
			{ x: new Date(2023, 11, 20), y: 20456.14 },
			{ x: new Date(2023, 11, 21), y: 22456.14 },
			{ x: new Date(2023, 11, 22), y: 24998.98 },
			{ x: new Date(2023, 11, 23), y: 21456.14 },
			{ x: new Date(2023, 11, 24), y: 23456.14 },
			{ x: new Date(2023, 11, 25), y: 21456.14 },
			{ x: new Date(2023, 11, 26), y: 23456.14 },
			{ x: new Date(2023, 11, 27), y: 20456.14 },
			{ x: new Date(2023, 11, 28), y: 21456.14 },
			{ x: new Date(2023, 11, 29), y: 22456.45 },
			{ x: new Date(2023, 11, 30), y: 24456.45 },
			{ x: new Date(2023, 11, 31), y: 25456.45 },

			{ x: new Date(2024, 0, 1), y: 19456.56 },
			{ x: new Date(2024, 0, 2), y: 21456.56 },
			{ x: new Date(2024, 0, 3), y: 18456.56 },
			{ x: new Date(2024, 0, 4), y: 20456.56 },
			{ x: new Date(2024, 0, 5), y: 21456.56 },
			{ x: new Date(2024, 0, 6), y: 24456.23 },
			{ x: new Date(2024, 0, 7), y: 19342.65 },
			{ x: new Date(2024, 0, 8), y: 21456.23 },
			{ x: new Date(2024, 0, 9), y: 24456.23 },
			{ x: new Date(2024, 0, 10), y: 22256.23 },
			{ x: new Date(2024, 0, 11), y: 23356.23 },
			{ x: new Date(2024, 0, 12), y: 22456.67 },
			{ x: new Date(2024, 0, 13), y: 20756.23 },
			{ x: new Date(2024, 0, 14), y: 21999.23 },
			{ x: new Date(2024, 0, 15), y: 20456.14 },
			{ x: new Date(2024, 0, 16), y: 19256.16 },
			{ x: new Date(2024, 0, 17), y: 19456.14 },
			{ x: new Date(2024, 0, 18), y: 23789.14 },
			{ x: new Date(2024, 0, 19), y: 22496.17 },
			{ x: new Date(2024, 0, 20), y: 22456.14 },
			{ x: new Date(2024, 0, 21), y: 24456.14 },
			{ x: new Date(2024, 0, 22), y: 23998.98 },
			{ x: new Date(2024, 0, 23), y: 20456.14 },
			{ x: new Date(2024, 0, 24), y: 22456.14 },
			{ x: new Date(2024, 0, 25), y: 23456.14 },
			{ x: new Date(2024, 0, 26), y: 24456.14 },
			{ x: new Date(2024, 0, 27), y: 21456.14 },
			{ x: new Date(2024, 0, 28), y: 23456.14 },
			{ x: new Date(2024, 0, 29), y: 25456.45 },
			{ x: new Date(2024, 0, 30), y: 28456.45 },
			{ x: new Date(2024, 0, 31), y: 29456.45 },

			{ x: new Date(2024, 1, 1), y: 19456.56 },
			{ x: new Date(2024, 1, 2), y: 21456.56 },
			{ x: new Date(2024, 1, 3), y: 18456.56 },
			{ x: new Date(2024, 1, 4), y: 20456.56 },
			{ x: new Date(2024, 1, 5), y: 21456.56 },
			{ x: new Date(2024, 1, 6), y: 24456.23 },
			{ x: new Date(2024, 1, 7), y: 19342.65 },
			{ x: new Date(2024, 1, 8), y: 21456.23 },
			{ x: new Date(2024, 1, 9), y: 24456.23 },
			{ x: new Date(2024, 1, 10), y: 22256.23 },
			{ x: new Date(2024, 1, 11), y: 23356.23 },
			{ x: new Date(2024, 1, 12), y: 22456.67 },
			{ x: new Date(2024, 1, 13), y: 20756.23 },
			{ x: new Date(2024, 1, 14), y: 21999.23 },
			{ x: new Date(2024, 1, 15), y: 20456.14 },
			{ x: new Date(2024, 1, 16), y: 19256.16 },
			{ x: new Date(2024, 1, 17), y: 19456.14 },
			{ x: new Date(2024, 1, 18), y: 23789.14 },
			{ x: new Date(2024, 1, 19), y: 22496.17 },
			{ x: new Date(2024, 1, 20), y: 22456.14 },
			{ x: new Date(2024, 1, 21), y: 24456.14 },
			{ x: new Date(2024, 1, 22), y: 23998.98 },
			{ x: new Date(2024, 1, 23), y: 20456.14 },
			{ x: new Date(2024, 1, 24), y: 22456.14 },
			{ x: new Date(2024, 1, 25), y: 23456.14 },
			{ x: new Date(2024, 1, 26), y: 24456.14 },
			{ x: new Date(2024, 1, 27), y: 21456.14 },
			{ x: new Date(2024, 1, 28), y: 23456.14 },
			{ x: new Date(2024, 1, 29), y: 25456.45 },

			{ x: new Date(2024, 2, 1), y: 19456.56 },
			{ x: new Date(2024, 2, 2), y: 21456.56 },
			{ x: new Date(2024, 2, 3), y: 18456.56 },
			{ x: new Date(2024, 2, 4), y: 20456.56 },
			{ x: new Date(2024, 2, 5), y: 21456.56 },
			{ x: new Date(2024, 2, 6), y: 24456.23 },
			{ x: new Date(2024, 2, 7), y: 19342.65 },
			{ x: new Date(2024, 2, 8), y: 21456.23 },
			{ x: new Date(2024, 2, 9), y: 24456.23 },
			{ x: new Date(2024, 2, 10), y: 22256.23 },
			{ x: new Date(2024, 2, 11), y: 23356.23 },
			{ x: new Date(2024, 2, 12), y: 22456.67 },
			{ x: new Date(2024, 2, 13), y: 20756.23 },
			{ x: new Date(2024, 2, 14), y: 21999.23 },
			{ x: new Date(2024, 2, 15), y: 20456.14 },
			{ x: new Date(2024, 2, 16), y: 19256.16 },
			{ x: new Date(2024, 2, 17), y: 19456.14 },
			{ x: new Date(2024, 2, 18), y: 23789.14 },
			{ x: new Date(2024, 2, 19), y: 22496.17 },
			{ x: new Date(2024, 2, 20), y: 22456.14 },
			{ x: new Date(2024, 2, 21), y: 24456.14 },
			{ x: new Date(2024, 2, 22), y: 23998.98 },
			{ x: new Date(2024, 2, 23), y: 20456.14 },
			{ x: new Date(2024, 2, 24), y: 22456.14 },
			{ x: new Date(2024, 2, 25), y: 23456.14 },
			{ x: new Date(2024, 2, 26), y: 24456.14 },
			{ x: new Date(2024, 2, 27), y: 21456.14 },
			{ x: new Date(2024, 2, 28), y: 23456.14 },
			{ x: new Date(2024, 2, 29), y: 25456.45 },
			{ x: new Date(2024, 2, 30), y: 28456.45 },
			{ x: new Date(2024, 2, 31), y: 29456.45 },
		].filter(d => moment(d.x).isBetween(moment().subtract(portfolioChartTimeFrame, 'months'), moment(), undefined, '[]'));

		const tempPortfolio = [
			{title: 'Pizza Cutter', type: 'patent', shares: 1000, price: 4.92, dayPriceChange: 0.56, performance: 2453.45},
			{title: 'VR Headset', type: 'trademark', shares: 925, price: 2.65, dayPriceChange: -0.23, performance: -547.78},
			{title: 'Mesh Basketball Net', type: 'patent', shares: 1000, price: 6.22, dayPriceChange: 2.75, performance: 1596.43}
		]

		const tempFollowing = [
			{title: 'Chainsaw Hat', type: 'patent', value: 10895.74, performance: 332.41},
			{title: 'Tennis Racket Restringer', type: 'patent', value: 777.56, performance: 847.78},
			{title: 'Tape Measure Rewinder', type: 'trademark', value: 600.56, performance: -2596.43},
			{title: 'Plant Based Nuggets', type: 'patent', value: 1600.56, performance: 456.43}
		]

		const convertToCurrency = (value) => {
			return value?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
		}

		const ToolTip = props => {

			const platformDifferential = Platform.OS === 'web' ? 10 : 0;

			const toolTipX = (value) => {
				let position = 0;
				if (value.x <= 50) position = value.x + platformDifferential;
				else if (value.x >= (mainColumnWidth - 50)) position = (100 + platformDifferential) - (mainColumnWidth - value.x);
				else position = (50 + platformDifferential);
				return position;
			}

			if (props.active && (props.index !== lastActiveTooltip.index)) setLastActiveTooltip(props);
		
			if (props.index === lastActiveTooltip.index) 
				return (
					<Svg>
						<Line x1={lastActiveTooltip.x} y1={20} x2={lastActiveTooltip.x} y2={500} stroke="#777777" strokeDasharray={3} />
						<Circle cx={lastActiveTooltip.x} cy={lastActiveTooltip.y} r="6" fill="#04FFFC" />
						<ForeignObject x={lastActiveTooltip.x-toolTipX(props)} y={0} width={120} height={70}>
							<View 
								style={{ 
									margin: 10,
									backgroundColor: '#FFF', 
									height: 50, 
									width: 100,
									borderRadius: 8, 
									borderWidth: 1, 
									borderColor: 'rgb(229, 229, 229)',
									justifyContent: 'center', 
									alignItems: 'center',
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 3.84,
									elevation: 5,
								}}>
								<Text color={'#000'} fontFamily={'Quicksand_600SemiBold'}>
									{lastActiveTooltip.datum.x.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric'})}
								</Text>
								<Text color={'#000'} fontFamily={'Quicksand_500Medium'} fontSize={12}>
									${convertToCurrency(lastActiveTooltip.datum.y)}
								</Text>
							</View>
						</ForeignObject>
					</Svg>
				)
			
			return
		}

    return (
			<ScrollView
				//flex={1}
				px={isMobile ? 4 : 16}
				py={isMobile ? 4 : 8}
			>
				<Box
					onLayout={(event) => { 
						const {x, y, width, height} = event.nativeEvent.layout;
						setMainColumnWidth(width)
					}}
					pb={16}	
				>
					{/* <Stack direction={["column", "column", "row"]} rounded="lg" overflow="hidden" shadow="1" _light={{backgroundColor: "coolGray.50"}} _dark={{	backgroundColor: "gray.700"}}> */}
					<Stack direction={["column", "column", "row"]} overflow="hidden" w={"100%"} space={4} mb={4}>
						<Card flex={1} px={6}>
							<HStack space={4} alignItems={'center'} justifyContent={'space-between'}>
								<Box>
									<Heading size="sm">
										Continue your project?
									</Heading>
									<Text>You have 1 active project.</Text>
								</Box>
								<Button 
									leftIcon={<Icon as={Octicons} name="project" size="md" />} 
									onPress={() => navigation.navigate('Projects')} 
									px={4}
								>
									Projects
								</Button>
							</HStack>
						</Card>
						<Card flex={1} px={6}>
							<HStack space={4} alignItems={'center'} justifyContent={'space-between'}>
								<Box>
									<Heading size="sm">
										Have an idea?
									</Heading>
									<Text>Get started with a new patent!</Text>
								</Box>
								<Button 
									leftIcon={<Icon as={Octicons} name="light-bulb" size="md" />}
									onPress={() => navigation.navigate('Create')} 
									px={4}
								>
									Create
								</Button>
							</HStack>
						</Card>
					</Stack>
					
							
					<Card 
						py={6}
						px={0}
						mb={4}
					>
						<Box flexDirection={'row'} justifyContent={'space-between'} mx={6} mb={1}>
							<Heading size="md">
								Portfolio Value
							</Heading>
							<HStack>
								{portfolioChartTimeFrames?.map((item, index) => (
									<Button 
										key={index} 
										onPress={() => setPortfolioChartTimeFrame(item.value)} 
										width={8} 
										height={8} 
										bgColor={item.value === portfolioChartTimeFrame ? 'darkBlue.400' : 'transparent'}
										_text={{fontSize: 10, color: item.value === portfolioChartTimeFrame ? '#FFF' : '#000'}} 
										p={0}
									>
										{item.label}
									</Button>
								))}
							</HStack>
						</Box>

						<Box mx={6} mb={1}>
							<Text fontSize={24} fontFamily={'Quicksand_500Medium'}>${convertToCurrency(portfolioChartData[portfolioChartData.length-1].y)}</Text>
							{(portfolioChartData[portfolioChartData.length-1].y - portfolioChartData[0].y) >= 0
								?	<HStack flex={1} alignItems={'center'} space={2}>
										<Feather name="trending-up" size={16} color={colors.green['500']} />
										<Text flex={1} fontSize={14} color={'green.500'}>{convertToCurrency(portfolioChartData[portfolioChartData.length-1].y - portfolioChartData[0].y)}</Text>
									</HStack>
								: <HStack flex={1} alignItems={'center'} space={2}>
										<Feather name="trending-down" size={16} color={colors.red['500']} />
										<Text flex={1} fontSize={14} color={'red.500'}>{convertToCurrency(portfolioChartData[portfolioChartData.length-1].y - portfolioChartData[0].y)}</Text>
									</HStack>
							}
						</Box>


						
						{/* <LineChart
							data={data}
							width={mainColumnWidth - 32}
							height={220}
							chartConfig={chartConfig}
							bezier
							withInnerLines={false}
							yAxisLabel="$"
							decorator={() => {
								return 	<Svg>
													<Rect x={80} y={110} width="40" height="30" fill="black" />
													<TextSVG
															x={100}
															y={130}
															fill="white"
															fontSize="16"
															fontWeight="bold"
															textAnchor="middle">
															0.0
													</TextSVG>
												</Svg>
							}}
						/> */}

							<VictoryChart
								//theme={VictoryTheme.material}
								containerComponent={
									<VictoryVoronoiContainer
										voronoiDimension="x"
									/>
								}
								width={mainColumnWidth}
								//height={400}
								padding={{ top: 80, bottom: 32, right: 0, left: 0 }}
								//domainPadding={{ x: 20 }}
							>
								<VictoryAxis 
									style={{ 
										axis: {
											stroke: colors.coolGray['200'] 
										},
										tickLabels: {
											fontFamily: 'Quicksand_500Medium' 
										}
									}}
									scale={{ x: "time" }}
									tickValues={portfolioChartData?.map(d => d.x)?.filter((d, i) => i > 4 && i < (portfolioChartData?.length - 3))}
									tickFormat={(x) => new Date(x).toLocaleDateString('en-us', { month: 'short', day: 'numeric'})} 
									fixLabelOverlap={true}
									tickCount={4}
								/>
								<VictoryArea
									style={{
										data: { stroke: '#04FFFC', strokeWidth: 2, fill: '#04FFFC', fillOpacity: 0.2 },
										parent: { border: '1px solid #ccc' },
									}}
									data={portfolioChartData}
									animate={{
										duration: 1000,
										//easing: "bounce"
									}}
									/* categories={{
										x: ["Jan 26", "Feb 5", "Feb 13", "Feb 22", "Mar 1"]
									}} */
								/>
								<VictoryScatter 
									data={portfolioChartData} 
									dataComponent={<ToolTip />} 
								/>
							</VictoryChart>

							{/* <LineChart
                data={{
                    labels: ["January", "February", "March", "April", "May", "June"],
                    datasets: [
                        {
                            data: [
                                100, 110, 90, 130, 80, 103
                            ]
                        }
                    ]
                }}
                width={mainColumnWidth - 48}
                height={250}
                yAxisLabel="$"
                yAxisSuffix="k"
                yAxisInterval={1}
                chartConfig={{
                    backgroundColor: "white",
                    backgroundGradientFrom: "#fbfbfb",
                    backgroundGradientTo: "#fbfbfb",
                    decimalPlaces: 2,
                    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                    labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                    style: {
                        borderRadius: 0
                    },
                    propsForDots: {
                        r: "6",
                        strokeWidth: "0",
                        stroke: "#fbfbfb"
                    }
                }}
                bezier
                style={{
                    marginVertical: 8,
                    borderRadius: 6
                }}

                decorator={() => {
                    return tooltipPos.visible
											? <Svg>
													<Rect x={tooltipPos.x - 15} 
														y={tooltipPos.y + 10} 
														width="40" 
														height="30"
														fill="black" 
													/>
													<TextSVG
															x={tooltipPos.x + 5}
															y={tooltipPos.y + 30}
															fill="white"
															fontSize="16"
															fontWeight="bold"
															textAnchor="middle">
															{tooltipPos.value}
													</TextSVG>
                        </Svg>
                     	: null
                }}
                onDataPointClick={(data) => {

                    let isSamePoint = (tooltipPos.x === data.x 
                                        && tooltipPos.y === data.y)

                    isSamePoint ? setTooltipPos((previousState) => {
                        return { 
                                  ...previousState,
                                  value: data.value,
                                  visible: !previousState.visible
                               }
                    })
                        : 
                    setTooltipPos({ x: data.x, value: data.value, y: data.y, visible: true });

                }}
            /> */}
						
					</Card>

					<Stack direction={["column", "column", "row"]} overflow="hidden" w={"100%"} space={4} mb={4}>
						<Card p={6} flex={1}>
							<Heading size="md" mb={5}>
								Portfolio List
							</Heading>

							<Box>
								<HStack space={4} alignItems={'center'} borderBottomWidth={2} borderBottomColor={'coolGray.200'} pb={1} mb={2}>
									<Text flex={1} fontSize={14} fontWeight={600}>Name</Text>
									<Text flex={1} fontSize={14} fontWeight={600}>Balance</Text>
									<Text flex={1} fontSize={14} fontWeight={600}>Price</Text>
									<Text flex={1} fontSize={14} fontWeight={600}>Performance</Text>
								</HStack>

								{tempPortfolio?.map((item, index) => (
									<HStack key={index} space={4} alignItems={'center'} mb={2}>
										<Box flex={1}>
											<Text fontSize={12}>{item.title}</Text>
											<Text fontSize={8} color={'coolGray.400'}>{item.type?.toUpperCase()}</Text>
										</Box>
										<Box flex={1}>
											<Text fontSize={12}>${convertToCurrency(item.shares * item.price)}</Text>
											<Text fontSize={8} color={'coolGray.400'}>{item.shares} shares</Text>
										</Box>
										<Box flex={1}>
											<Text fontSize={12}>${item.price}</Text>
											<HStack flex={1} alignItems={'center'} space={1}>
												{item.dayPriceChange >= 0
													? <HStack flex={1} alignItems={'center'} space={1}>
															<Feather name="trending-up" size={12} color={colors.green['500']} />
															<Text flex={1} fontSize={8} color={'green.500'}>{convertToCurrency(item.dayPriceChange)}%</Text>
														</HStack>
													: <HStack flex={1} alignItems={'center'} space={1}>
															<Feather name="trending-down" size={12} color={colors.red['500']} />
															<Text flex={1} fontSize={8} color={'red.500'}>{convertToCurrency(item.dayPriceChange)}%</Text>
														</HStack>
												}
											</HStack>
										</Box>
										{item.performance >= 0
											? <HStack flex={1} alignItems={'center'} space={2}>
													<Feather name="trending-up" size={16} color={colors.green['500']} />
													<Text flex={1} fontSize={14} color={'green.500'}>${item.performance?.toLocaleString()}</Text>
												</HStack>
											: <HStack flex={1} alignItems={'center'} space={2}>
													<Feather name="trending-down" size={16} color={colors.red['500']} />
													<Text flex={1} fontSize={14} color={'red.500'}>${item.performance?.toLocaleString()}</Text>
												</HStack>
										}
										
									</HStack>
								))}
								
							</Box>
							
							{/* <LineChart
								data={data}
								width={(mainColumnWidth / 2) - 32}
								height={220}
								chartConfig={chartConfig}
								bezier
								withInnerLines={false}
								yAxisLabel="$"
							/> */}
						</Card>
						<Card p={6} flex={1}>
							<Heading size="md" mb={5}>
								Following
							</Heading>

							<Box>
								<HStack space={4} alignItems={'center'} borderBottomWidth={2} borderBottomColor={'coolGray.200'} pb={1} mb={2}>
									<Text flex={1} fontSize={14} fontWeight={600}>Name</Text>
									<Text flex={1} fontSize={14} fontWeight={600}>Price</Text>
									<Text flex={1} fontSize={14} fontWeight={600}>Performance</Text>
								</HStack>

								{tempFollowing?.map((item, index) => (
									<HStack key={index} space={4} alignItems={'center'} mb={2}>
										<Box flex={1}>
											<Text fontSize={12}>{item.title}</Text>
											<Text fontSize={8} color={'coolGray.300'}>{item.type?.toUpperCase()}</Text>
										</Box>
										<Text flex={1} fontSize={14}>${item.value?.toLocaleString()}</Text>
										{item.performance >= 0
											? <HStack flex={1} alignItems={'center'} space={2}>
													<Feather name="trending-up" size={16} color={colors.green['500']} />
													<Text flex={1} fontSize={14} color={'green.500'}>${item.performance?.toLocaleString()}</Text>
												</HStack>
											: <HStack flex={1} alignItems={'center'} space={2}>
													<Feather name="trending-down" size={16} color={colors.red['500']} />
													<Text flex={1} fontSize={14} color={'red.500'}>${item.performance?.toLocaleString()}</Text>
												</HStack>
										}
										
									</HStack>
								))}
								
							</Box>
			
						</Card>
					</Stack>

					
								
						{/* {user.isAnonymous ? (
								<Text color="plainText.800">User is a guest</Text>
						) : (
								<VStack alignItems="center" justifyContent="center">
										<Avatar
												backgroundColor="primary.500"
												mb={4}
												size="xl"
												source={{
														uri: user.image || undefined,
												}}>
												{user.firstName?.length &&
														user.lastName?.length &&
														`${user?.firstName[0]?.toUpperCase()}${user?.lastName[0]?.toUpperCase()}`}
										</Avatar>
										<Text fontWeight="bold" color="plainText.800" fontSize="xl">
												{user.firstName} {user.lastName}
										</Text>
										<Text color="plainText.800">Account Email: {user.email}</Text>
										<Text color="plainText.800">Email Verified: {String(user.emailVerified)}</Text>
								</VStack>
						)}
						<Text color="plainText.800">User ID: {user.id}</Text>
						<Box py={3}>
								<Text color="plainText.800" bold>
										Really fun user data counter: {user.count}
								</Text>
								<Button m={2} onPress={() => dispatch(incrementCount())}>
										Increment Count
								</Button>
								<Button m={2} onPress={() => dispatch(decrementCount())}>
										Decrement Count
								</Button>
						</Box>
						{user.isAnonymous ? (
								<Button mt="2" colorScheme="indigo" onPress={() => navigation.navigate('Auth')}>
										Login to real account
								</Button>
						) : null}
						<Button
								isLoading={isFetching}
								mt="2"
								colorScheme="indigo"
								onPress={() => (user.loggedIn ? signOut(undefined) : navigation.navigate('Auth'))}>
								{user.loggedIn ? 'Logout' : 'Login'}
						</Button> */}
				</Box>
			</ScrollView>
	);
};
