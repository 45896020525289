import React from 'react';
import { TextArea } from 'native-base';

function CustomTextArea({field, form, ...props}) {

  return (
    <TextArea
      //borderWidth={0}
      //borderBottomWidth={1}
      borderRadius={20}
      borderColor={'coolGray.300'}
      outlineStyle={'none'}
      px={4}
      fontSize={16}
      onChangeText={(text) => form.setFieldValue(field.name, text)}
      value={field.value}
      _focus={{
        //borderWidth: 0,
        backgroundColor: 'none',
        borderColor: 'coolGray.300',
        //outlineStyle: 'none',
      }}
      _hover={{
        //backgroundColor: 'transparent',
        borderColor: 'coolGray.300',
      }}
      {...props}
      //{...field}
      
    />
  );
}

export default CustomTextArea;