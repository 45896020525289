import React from 'react';
import { useTheme } from '@react-navigation/native';
import { Button } from 'native-base';

function CustomButton({...rest}) {

  const { colors } = useTheme();

  return (
    <Button
      rounded="full"
      bgColor={'darkBlue.400'}
      maxWidth={300}
      {...rest}
    />
  );
}

export default CustomButton;
