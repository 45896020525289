import React, { useEffect, useState, useCallback } from 'react';
import { Box, Center, useColorModeValue } from 'native-base';
import { StyleSheet, View, TouchableOpacity, Text, Image, Animated, Pressable, useWindowDimensions } from 'react-native';
import { ProjectsStackParams } from 'src/navigation/projects-stack';
import { StackScreenProps } from '@react-navigation/stack';
import { GiftedChat, IMessage, User, Bubble, Send, Composer, ComposerProps, SendProps } from 'react-native-gifted-chat';
import { useAppSelector } from 'src/ducks/useful-hooks';
import { db, storage } from 'src/firebase/config';
import { collection, doc, setDoc, query, where, orderBy, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import * as DocumentPicker from 'expo-document-picker';
import InChatFileTransfer from 'src/components/in-chat-file-transfer';
import InChatViewFile from 'src/components/in-chat-view-file';
import { FontAwesome } from '@expo/vector-icons'; 
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

type ProjectScreenProps = StackScreenProps<ProjectsStackParams, 'Project'>;

interface MessageData {
  messageID: string, 
  createdDateTime: string, 
  text: string, 
  userID: string, 
  projectID: string, 
  imageURL: string, 
  fileURL: string,
  fileType: string
}

export /**
 * Project screen, dashboard for project.
 *
 * @param {*} { route, navigation }
 * @return {*}
 */
const ProjectScreen: React.FC<ProjectScreenProps> = ({ route, navigation }) => {

  const Tab = createMaterialTopTabNavigator();
  
  return (    
    <Tab.Navigator 
      sceneContainerStyle={{backgroundColor: 'white'}}
      screenOptions={{
        lazy: true,
        lazyPlaceholder: () => <Center flex={1} my="4">Loading...</Center>
      }}
    >
      <Tab.Screen 
        name="Tasks" 
        component={TasksScreen} 
      />
      <Tab.Screen 
        name="Files" 
        component={FilesScreen} 
      />
      <Tab.Screen 
        name="Messages" 
        children={props => <ChatScreen parentRoute={route} {...props} />} 
        options={{
          tabBarIcon: () => <View style={{height: 10, width: 10, backgroundColor: 'green'}} />,
        }}
      />
    </Tab.Navigator>
  );

  /* const renderScene = SceneMap({
    first: ConversationsScreen,
    second: FilesScreen,
    third: TasksScreen
  });

  /* const renderScene = ({ route }) => {
    switch (route.key) {
      case 'chat':
        return <ChatScreenMemo navRoute={navRoute} />;
      case 'files':
        return <FilesScreenMemo />;
      case 'tasks':
        return <TasksScreenMemo />;
      default:
        return null;
    }
  };

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'chat', title: 'Chat' },
    { key: 'files', title: 'Files' },
    { key: 'tasks', title: 'Tasks' },
  ]);

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      //initialLayout={{ width: layout.width }}
      lazy
      //renderLazyPlaceholder={() => <Center flex={1} my="4">Loading...</Center>}
    />
  ); */

};

const ChatScreen = ({parentRoute}) => {

  const user = useAppSelector((state) => state.user);
  const { project } = parentRoute.params;
  const [isAttachImage, setIsAttachImage] = useState(false);
  const [isAttachFile, setIsAttachFile] = useState(false);
  const [fileVisible, setFileVisible] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [filePath, setFilePath] = useState('');
  const [file, setFile] = useState();
  const [messages, setMessages] = useState<MessageData[]>([])

  useEffect(() => {

    const q = query(
      collection(db, 'messages'), 
      where('projectID', '==', project.id), 
      //where('userID', '==', user.id), 
      orderBy('createdDateTime', 'desc')
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      //Check to make sure server has written all data before setting messages
      if (!snapshot.metadata.hasPendingWrites) setMessages(snapshot.docs.map(doc => Object.assign(doc.data(), {createdDateTime: doc.data().createdDateTime?.toDate()})))
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const ChatComposer = (
    props: ComposerProps & {
      onSend: SendProps<IMessage>["onSend"]
      text: SendProps<IMessage>["text"]
    }
  ) => {
      return (
        <Composer
          {...props}
          textInputStyle={{
            paddingTop: 0,
            paddingBottom: 6
          }}
          textInputProps={{
            ...props.textInputProps,
            blurOnSubmit: false,
            multiline: false,
            onSubmitEditing: () => {
              if (props.text && props.onSend) {
                props.onSend({ text: props.text.trim() }, true)
              }
            },
          }}
        />
      )
  }

  const onSend = useCallback(async(messages = []) => {
    
    try {
      const { text } = messages[0];
      const newMessageRef = doc(collection(db, 'messages'));

      const newMessage = {
        messageID: newMessageRef.id,
        createdDateTime: serverTimestamp(), 
        text, 
        userID: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        projectID: project.id,
        fileURL: '',
        fileType: ''
      };

      if (file) {
        newMessage.fileType = file.mimeType.split('/').pop();
        const _file = await fetch(file.uri);
        const blobFile = await _file.blob();
        const storageRef = ref(storage, `messages/${newMessage.messageID}`);
        await uploadBytesResumable(storageRef, blobFile);
        newMessage.fileURL = await getDownloadURL(storageRef);
      }

      setDoc(newMessageRef, newMessage);

      /* setImagePath('');
      setIsAttachImage(false);
      setFilePath('');
      setIsAttachFile(false); */
      setFile(null)

    } catch (err) {
      console.log(err)
    }

  }, [file]);

  // add a function attach file using DocumentPicker.pick
  const _pickDocument = async () => {
    try {
      /* const result = await DocumentPicker.pick({
        type: [DocumentPicker.types.allFiles],
        copyTo: 'documentDirectory',
        mode: 'import',
        allowMultiSelection: true,
      }); */
      const result = await DocumentPicker.getDocumentAsync({});
      if (!result) {
        console.log('File not found');
        return;
      }
      console.log(result)
      /* if (result.mimeType == 'image/png' || result.mimeType == 'image/jpg' || result.mimeType == 'image/jpeg') {
        setImagePath(result.uri);
        setIsAttachImage(true);
      } else {
        setFilePath(result.uri);
        setIsAttachFile(true);
      } */
      setFile(result)
    } catch (err) {
      console.log(err)
      /* if (DocumentPicker.isCancel(err)) {
        console.log('User cancelled file picker');
      } else {
        console.log('DocumentPicker err => ', err);
        throw err;
      } */
    }
  };

  const renderSend = (props:any) => {
    return (
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity onPress={_pickDocument}>
          <FontAwesome 
            name="paperclip" 
            size={28} 
            color="gray" 
            style={styles.paperClip} 
          />
        </TouchableOpacity>
        <Send {...props}>
          <View style={styles.sendContainer}>
            <FontAwesome 
              name="send" 
              size={25} 
              color="green" 
              style={styles.sendButton} 
            />
          </View>
        </Send>
      </View>
    );
  };
  
  const renderBubble = (props:any) => {
    const {currentMessage} = props;

    //If createdAt isn't available yet don't display
    if (!currentMessage.createdAt) return;

    if (currentMessage.file) {
      return (
        <TouchableOpacity
          style={{
            ...styles.fileContainer,
            backgroundColor: currentMessage.user._id === 2 ? '#2e64e5' : '#efefef',
            borderBottomLeftRadius: currentMessage.user._id === 2 ? 15 : 5,
            borderBottomRightRadius: currentMessage.user._id === 2 ? 5 : 15,
          }}
          onPress={() => setFileVisible(true)}
        >
          <InChatFileTransfer
            file={file}
          />
          <InChatViewFile
            fileURL={currentMessage.fileURL}
            visible={fileVisible}
            onClose={() => setFileVisible(false)}
          />
          <View style={{flexDirection: 'column'}}>
            <Text style={{
              ...styles.fileText,
              color: currentMessage.user._id === 2 ? 'white' : 'black',
            }} >
              {currentMessage.text}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          right: {
            backgroundColor: '#2e64e5',
          },
        }}
        textStyle={{
          right: {
            color: '#efefef',
          },
        }}
      />
    );
  };

  const renderCustomView = (props:any) => {
    const {currentMessage} = props;

    //If createdAt isn't available yet don't display
    if (!currentMessage.createdAt || !currentMessage.fileURL) return;

    return (
      <TouchableOpacity
        /* style={{
          ...styles.fileContainer,
          backgroundColor: currentMessage.user._id === 2 ? '#2e64e5' : '#efefef',
          borderBottomLeftRadius: currentMessage.user._id === 2 ? 15 : 5,
          borderBottomRightRadius: currentMessage.user._id === 2 ? 5 : 15,
        }} */
        onPress={() => console.log("CLICKED")}
      >
        <Image source={{uri: currentMessage.fileURL}} style={{height: 75, width: 75}} />
        {/* <InChatFileTransfer
          file={file}
        /> */}
        {/* <InChatViewFile
          fileURL={currentMessage.fileURL}
          visible={fileVisible}
          onClose={() => setFileVisible(false)}
        />
        <View style={{flexDirection: 'column'}}>
          <Text style={{
            ...styles.fileText,
            color: currentMessage.user._id === 2 ? 'white' : 'black',
          }} >
            {currentMessage.text}
          </Text>
        </View> */}
      </TouchableOpacity>
    );

  };

  const renderChatFooter = useCallback(() => {
    if (file) {
      // isImage = (file.mimeType == 'image/png' || file.mimeType == 'image/jpg' || file.mimeType == 'image/jpeg')
      return (
        <View style={styles.chatFooter}>
          {isImage(file.name)
            ? <Image source={{uri: file.uri}} style={{height: 75, width: 75}} />
            : <InChatFileTransfer file={file} />
          }
          <TouchableOpacity
            onPress={() => setFile(null)}
            style={isImage(file.name) ? styles.buttonFooterChatImg : styles.buttonFooterChat}
          >
            <Text style={styles.textFooterChat}>X</Text>
          </TouchableOpacity>
        </View>
      );
    }
    return null;
  }, [file]);

  const isImage = (name) => {
    return name.match(/\.(jpg|jpeg|png|gif)$/i);
  }
  
  const scrollToBottomComponent = () => {
    //return <FontAwesome name="angle-double-down" size={22} color="#333" />;
    return <Text>Scroll to Bottom</Text>
  };

  //Convert database messages to gifted chat format
  const giftedChatMessages = messages.map((message) => {

    const { messageID, createdDateTime, text, userID, projectID, fileURL, fileType, firstName, lastName } = message;

    let gcm = {
      _id: messageID,
      createdAt: createdDateTime,
      text,
      user: {
        _id: userID,
        name: `${firstName} ${lastName}`,
        //avatar: chatMessage.get("user").get("avatarUrl")
      },
      projectID: projectID,
      //image: fileType ? (fileType === 'png' ? fileURL : require('assets/chat_file.png')) : '',
      image: fileType ? ((fileType === 'png' || fileType === 'jpeg') ? fileURL : '') : '',
      fileURL: fileURL
    };
    return gcm;
  });

  const chatUser: User = { 
    _id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    //avatar: user.profileImage 
  }

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <GiftedChat
        messages={giftedChatMessages}
        onSend={messages => onSend(messages)}
        user={chatUser}
        //renderBubble={renderBubble}
        //renderCustomView={renderCustomView}
        alwaysShowSend
        renderSend={renderSend}
        //scrollToBottom
        //scrollToBottomComponent={scrollToBottomComponent}
        renderChatFooter={renderChatFooter}
        //isTyping={true}
        //loadEarlier={true}
        renderComposer={ChatComposer}
      />
    </View>
  )

}

const FilesScreen = () => {

  return (
    <Center flex={1} my="4">This is the Files tab</Center>
  )
}

const TasksScreen = () => {

  return (
    <Center flex={1} my="4">This is the Tasks tab</Center>
  )
}

/* const ChatScreenMemo = React.memo(ChatScreen);
const FilesScreenMemo = React.memo(FilesScreen);
const TasksScreenMemo = React.memo(TasksScreen); */

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  paperClip: {
    marginTop: 8,
    marginHorizontal: 5,
    transform: [{rotateY: '180deg'}],
  },
  sendButton: {marginBottom: 10, marginRight: 10},
  sendContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chatFooter: {
    shadowColor: '#1F2687',
    shadowOpacity: 0.37,
    shadowRadius: 8,
    shadowOffset: {width: 0, height: 8},
    elevation: 8,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.18)',
    flexDirection: 'row',
    padding: 5,
    //backgroundColor: 'blue'
  },
  fileContainer: {
    flex: 1,
    maxWidth: 300,
    marginVertical: 2,
    borderRadius: 15,
  },
  fileText: {
    marginVertical: 5,
    fontSize: 16,
    lineHeight: 20,
    marginLeft: 10,
    marginRight: 5,
  },
  textTime: {
    fontSize: 10,
    color: 'gray',
    marginLeft: 2,
  },
  buttonFooterChat: {
    width: 35,
    height: 35,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderColor: 'black',
    right: 3,
    top: -2,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  buttonFooterChatImg: {
    width: 35,
    height: 35,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderColor: 'black',
    left: 66,
    top: -4,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  textFooterChat: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'gray',
  },
});
