import {
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	OrderByDirection,
	query,
	QueryDocumentSnapshot,
	QuerySnapshot,
	setDoc,
	startAfter,
} from 'firebase/firestore';
import { projectCollection } from 'src/firebase/config';
import { firebaseHandler, firestoreGetHandler } from 'src/firebase/handler';
import { ProjectData } from 'src/types';

/**
 * Function will update the project with the input fields, will overwrite if newProject set to True
 *
 * @export
 * @param {ProjectData} projectData
 * @param {boolean} [newProject]
 * @return {*}  {Promise<void>}
 */
export async function saveProjectData(
	projectData: { id: string } & Partial<ProjectData>
): Promise<void> {
	const newProject = !projectData.id;
	const args = [projectCollection];

	//Check if new project and add id if existing
	if (!newProject) args.push(projectData.id)
	const projectRef = doc(...args);

	//If new project set id from reference
	if (newProject) projectData.id = projectRef.id

	return firebaseHandler<void>(setDoc(projectRef, projectData, { merge: true }));
}

/**
 * Function will get the project data
 *
 * @export
 * @param {string} projectID
 * @return {*}  {Promise<QueryDocumentSnapshot<ProjectData>>}
 */
export async function getProjectData(
	projectID: string,
): Promise<QueryDocumentSnapshot<ProjectData>> {
	const projectRef = doc(projectCollection, projectID);
	return firestoreGetHandler<ProjectData>(getDoc(projectRef));
}

/**
 * Function will delete the project data
 *
 * @remarks
 * Firebase functions will automatically delete the public facing data
 *
 * @export
 * @param {string} projectID
 * @return {*}  {Promise<void>}
 */
export async function deleteProjectData(projectID: string): Promise<void> {
	const projectRef = doc(projectCollection, projectID);
	return firebaseHandler<void>(deleteDoc(projectRef));
}

/**
 * Will fetch the projects from the public project data
 *
 * @export
 * @param {(string | undefined)} [begID=undefined] - the beginning id of the last document
 * @param {number} [lim=10] - limit of documents to fetch
 * @param {OrderByDirection} [direction='desc'] - which direction to order the documents by
 * @return {*}  {Promise<QuerySnapshot<ProjectData>>}
 */
export async function getProjects(
	begID: string | undefined = undefined,
	lim: number = 10,
	direction: OrderByDirection = 'desc',
): Promise<QuerySnapshot<ProjectData>> {
	let q;
	const lastVisible = begID ? await getDoc(doc(projectCollection, begID)) : undefined;

	if (lastVisible?.exists()) {
		q = query(
			projectCollection,
			//orderBy('count', direction),
			startAfter(lastVisible),
			limit(lim),
		);
	} else {
		//q = query(projectCollection, orderBy('count', direction), limit(lim));
		q = query(projectCollection);
	}
	return getDocs(q);
}
