import React from 'react';
import { Input } from 'native-base';

function CustomInput({field, form, hide, ...props}) {

  return (
    (!hide || (form.values[hide?.field] !== hide?.value)) &&
      <Input
        //borderWidth={0}
        //borderBottomWidth={1}
        borderRadius={9999}
        borderColor={'coolGray.300'}
        outlineStyle={'none'}
        px={4}
        fontSize={16}
        height={44}
        onChangeText={(text) => form.setFieldValue(field.name, text)}
        value={field.value}
        _focus={{
          //borderWidth: 0,
          backgroundColor: 'none',
          borderColor: 'coolGray.300',
          //outlineStyle: 'none',
        }}
        _hover={{
          //backgroundColor: 'transparent',
          borderColor: 'coolGray.300',
        }}
        {...props}
        //{...field}
      />
  );
}

export default CustomInput;