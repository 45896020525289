import React from 'react';
import { useWindowDimensions, StyleSheet, Platform, View, Text, StatusBar, Image, TouchableOpacity } from "react-native";
//import { createDrawerNavigator } from '@react-navigation/bottom-tabs';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
  DrawerToggleButton
} from '@react-navigation/drawer';
import { HomeScreen, CreateScreen, ExploreScreen, ProjectsScreen } from 'src/screens';
import { Box, Icon, IconButton, Avatar, useTheme } from 'native-base';
import { Octicons } from '@expo/vector-icons';
import { useAppSelector } from 'src/ducks/useful-hooks';
import { SettingsStack } from './settings-stack';
import { ProjectsStack } from './projects-stack';

const CustomDrawerContent = (props) => {

  return (
    <DrawerContentScrollView {...props} >
      <Box 
        height={100}
        borderBottomColor={'#50648C'} 
        borderBottomWidth={2}
        marginX={5}
        marginBottom={5}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {/* <Octicons name="circle" size={48} color="#FFF" /> */}
        <Image source={require('assets/icon.png')} style={{height: 56, width: 56}} />
      </Box>
      <DrawerItemList {...props} />
      {/* <DrawerItem
        label="Close drawer"
        onPress={() => props.navigation.closeDrawer()}
      />
      <DrawerItem
        label="Toggle drawer"
        onPress={() => props.navigation.toggleDrawer()}
      /> */}
    </DrawerContentScrollView>
  );
}


const Drawer = createDrawerNavigator();
/*
    Define Icons
*/
/* interface TabBarIconProps {
    focused: boolean;
    color: string;
    size: number;
}
const HomeIcon = ({ focused, color, size }: TabBarIconProps) => (
    <MaterialCommunityIcons name={focused ? 'home' : 'home-outline'} color={color} size={size} />
);

const ExploreIcon = ({ focused, color, size }: TabBarIconProps) => (
    <MaterialIcons name={focused ? 'search' : 'search'} color={color} size={size} />
); */

export /**
 *Bottom Tab Navigator, used for Navigating between all bottom tab screens
 *
 * @return {*}
 */
const DrawerNavigator: React.FC<{}> = () => {

  const isAnonymous = useAppSelector((state) => state.user.isAnonymous);
  const dimensions = useWindowDimensions();
  const isMobile = dimensions.width < 1024;
  const { colors } = useTheme();

  const AlertsButton = (onPress?: (event: GestureResponderEvent) => void, isDisabled?: boolean) => (
    <TouchableOpacity 
      onPress={onPress} 
      style={{
        /* backgroundColor: colors.coolGray['100'], 
        height: 48, 
        width: 48, 
        justifyContent: 'center', 
        alignItems: 'center', 
        borderWidth: 2,
        borderColor: colors.coolGray['300'],
        borderRadius: 9999, */
        marginRight: 24,
      }}
    >
      <Octicons name="bell" size={24} color="#000" />
    </TouchableOpacity>
  );

  const ProfileButton = (onPress?: (event: GestureResponderEvent) => void, isDisabled?: boolean) => (
    <TouchableOpacity 
      onPress={onPress} 
    >
      <Avatar 
        style={{
          /* height: 48, 
          width: 48, 
          justifyContent: 'center', 
          alignItems: 'center', 
          borderWidth: 2,
          borderColor: colors.coolGray['300'], */
        }} 
        source={{
          uri: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
        }}
      >
        AJ
      </Avatar>
    </TouchableOpacity>
  );

  return (
    <Drawer.Navigator
      //useLegacyImplementation
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={({navigation}) => ({
        //headerShown: false,
        //tabBarIcon: HomeIcon,
        //headerTitle: 'Dashboard',
        header: (props) => 
          <View style={{ 
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            //paddingTop: (StatusBar.currentHeight || 0) + (isMobile ? 16 : 48), 
            //paddingBottom: isMobile ? 16 : 48,
            marginTop: (StatusBar.currentHeight || 0),
            paddingVertical: 16,
            paddingHorizontal: isMobile ? 16 : 32,
            backgroundColor: '#FFF',
            borderBottomColor: '#E5E5E5',
            borderBottomWidth: 2
          }}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              {isMobile ? <DrawerToggleButton/> : null}
              <Text style={{fontFamily: 'Quicksand_700Bold', fontSize: 24, marginBottom: 6}}>{props.options?.headerTitle}</Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              {AlertsButton(() => navigation.navigate('SettingsStack'), isAnonymous)}
              {ProfileButton(() => navigation.navigate('SettingsStack'), isAnonymous)}
            </View>
          </View>,
        /* headerLeft: () => isMobile ? <DrawerToggleButton/> : null,
        headerRight: () => SettingsButton(() => navigation.navigate('Settings'), isAnonymous),
        headerLeftContainerStyle: {
          marginHorizontal: isMobile ? 0 : 48
        },
        headerRightContainerStyle: {
          marginRight: isMobile ? 0 : 48
        },
        headerStyle: {xew
          borderBottomWidth: 0,
        },
        headerTitleStyle: {
          fontFamily: 'Quicksand_700Bold',
          fontSize: 30,
        }, */
        swipeEnabled: false,
        drawerType: isMobile ? 'front' : 'permanent',
        drawerStyle: {
          backgroundColor: '#05255C',
          width: 220,
        },
        drawerActiveBackgroundColor: colors.darkBlue['400'],
        //drawerInactiveBackgroundColor: '#50648C',
        drawerActiveTintColor: '#FFF',
        drawerInactiveTintColor: '#FFF',
        drawerItemStyle: {
          marginHorizontal: 20,
          paddingHorizontal: 8,
          borderRadius: 9999,
          //marginBottom: 20,
          //paddingHorizontal: 10,
          //height: 100,
          //width: 138,
          //borderRadius: 20,
          //margin: 0,
          //justifyContent: 'center',
          //marginLeft: -38
        },
      })}
    >
      <Drawer.Screen
        name="Dashboard"
        component={HomeScreen}
        options={{
          headerTitle: 'Dashboard',
          drawerIcon: () => <Octicons name="home" size={22} color="#FFF" width={20}/>,
          drawerLabelStyle: [styles.drawerLabelStyle]
        }}
      />
      <Drawer.Screen
        name="Projects"
        component={ProjectsStack}
        options={{
          headerTitle: 'Projects',
          drawerLabel: 'Projects',
          drawerIcon: () => <Octicons name="project" size={20} color="#FFF" width={20}/>,
          drawerLabelStyle: [styles.drawerLabelStyle]
        }}
      />
      <Drawer.Screen
        name="Create"
        component={CreateScreen}
        options={{
          headerTitle: 'Create',
          drawerIcon: () => <Octicons name="light-bulb" size={22} color="#FFF" width={20}/>,
          drawerLabelStyle: [styles.drawerLabelStyle]
        }}
      />
      <Drawer.Screen
        name="Explore"
        component={ExploreScreen}
        options={{
          headerTitle: 'Explore',
          drawerIcon: () => <Octicons name="search" size={20} color="#FFF" width={20}/>,
          drawerLabelStyle: [styles.drawerLabelStyle]
        }}
      />
      <Drawer.Screen
        name="SettingsStack"
        component={SettingsStack}
        options={{ 
          drawerItemStyle: {display: 'none'}
        }}
      />
    </Drawer.Navigator>
  );

};

const styles = StyleSheet.create({
  drawerLabelStyle: {
    //textAlign: 'center',
    marginLeft: -16,
    fontFamily: 'Quicksand_600SemiBold',
    //position: 'absolute',
    //left: -10,
    //top: 16,
    //fontSize: 12,
    overflow: 'visible',
  }
})
