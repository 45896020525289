import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectApi } from 'src/services/project-api';
import { ProjectData } from 'src/types';

/**
 * Define the initial project
 */
const initialProject: ProjectData = {
    id: '',
    title: '',
    description: '',
    type: ''
};

/**
 * Define the intiaial project slice object
 * @resources
 * https://redux-toolkit.js.org/api/createSlice
 */
const projectSlice = createSlice({
    name: 'project',
    initialState: initialProject,

    /**
     * Define the reducers for this slice
     */
    reducers: {
        incrementCount: (state) => {
            // increment, or set to 1
            //state.count ? (state.count += 1) : (state.count = 1);
        },
        decrementCount: (state) => {
            // decrement, or set to -1
            //state.count ? (state.count -= 1) : (state.count = -1);
        },
    },

    /**
     * For syncing with rtk-query, updating the local state when a query fetches
     *
     * @remarks
     * Only need these extra reducers for many actions that are handling the universal state for the project
     */
    extraReducers: (builder) => {
        /**
         * When the project updates certain fields, update the local state.
         *
         * @param {*} _state
         * @param {PayloadAction<string>} action
         * @return {*}
         */
        builder.addMatcher(
            ProjectApi.endpoints.saveProjectFields.matchFulfilled,
            (state, action: PayloadAction<ProjectData>) => {
                return { ...state, ...action.payload };
            },
        );

        /**
         * When a project is deleted, reset the state to the initial project
         *
         * @param {*} _state
         * @param {PayloadAction<ProjectData>} action
         * @return {*}
         */
        //builder.addMatcher(AuthApi.endpoints.deleteAccount.matchFulfilled, () => initialProject);
    },
});

/**
 * Export the corresponding redux actions
 */
export const { incrementCount, decrementCount } = projectSlice.actions;
export default projectSlice.reducer;
