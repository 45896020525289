import React, { useEffect, useState } from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';
import { Box, Spinner, Text, HStack, Avatar, ScrollView, Stack, VStack, Image, Center, Heading } from 'native-base';
import { useGetProjectsQuery } from 'src/services/project-api';
import { ProjectData } from 'src/types';
import { StackScreenProps } from '@react-navigation/stack';
import { ProjectsStackParams } from 'src/navigation/projects-stack';
import { useAppSelector } from 'src/ducks/useful-hooks';
import { db } from 'src/firebase/config';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { Card } from 'src/components/card';

type ProjectsScreenProps = StackScreenProps<ProjectsStackParams, 'Projects'>;

export /**
 * Projects Screen - where the user will be able to view their projects.
 *
 * @param {*} { navigation }
 * @return {*}
 */
const ProjectsScreen: React.FC<ProjectsScreenProps> = ({ navigation }) => {
	const user = useAppSelector((state) => state.user);
	const [isFetching, setIsFetching] = useState<boolean>(true);
	// state and query hooks
	const [begID, setBegID] = useState<string | undefined>(undefined);
	//const { data = [], isFetching, refetch } = useGetProjectsQuery(begID);
	const [projects, setProjects] = useState<Array<ProjectData>>([]);
	const dimensions = useWindowDimensions();
	const isMobile = dimensions.width < 1024;

	/* useEffect(() => {
		// concat if there are more projects, otherwise set equal to data
		if (begID) {
			setProjects(projects.concat(data));
		} else {
			setProjects(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data[0]?.id]); */

	useEffect(() => {
    fetchData()
  }, []);

	const fetchData = async () => {
		try {
			setIsFetching(true)
			let q;
			if (user.role === 'admin') {
				q = query(collection(db, 'projects'));
			} else {
				q = query(collection(db, 'projects'), where('userIDs', 'array-contains', user.id));
			}
    	await getDocs(q).then(snapshot => setProjects(snapshot.docs.map(doc => Object.assign(doc.data()))))
		} catch (error) {
			console.log(error)
		} finally {
			setIsFetching(false)
		}
	}

	return (
		<ScrollView
			//flex={1}
			px={isMobile ? 4 : 16}
			py={isMobile ? 4 : 8}
		>
			<Stack 
				direction={["column", "column", "column"]} 
				//width={["100%", "100%", "25%"]} 
				//flexWrap={'wrap'}
				space={4}
			>
				{projects.map((project, index) => (
					<TouchableOpacity key={index} onPress={() => navigation.navigate('Project', {project})}>
						<Card p={0}>
							<Stack 
								direction={["column", "column", "row"]} 
								//rounded="lg" 
								overflow="hidden" 
								//width={["72", "72", "4/6"]} 
								//height={["96", "96", "48"]} 
								bgColor={'white'}
								borderRadius={20}
							>
								<Box w={["100%", "100%", "80"]} h={["50%", "50%", "48"]}>
									<Image 
										w={["100%", "100%", "80"]} 
										h="100%" 
										source={{
											uri: "https://www.ige.ch/fileadmin/_processed_/4/4/csm_Patentzeichnung_770x420px_f9b1a2839f.jpg"
										}} 
										alt="image" 
									/>
									<Center 
										bg="#34F5C6" 
										_text={{
											color: "white",
											fontWeight: "700",
											fontSize: "xs"
										}} 
										position="absolute" 
										bottom="0" 
										px="3" 
										py="1.5"
									>
										{project.type?.toUpperCase()}
									</Center>
								</Box>
								<Stack 
									flex="1" p="4" 
									space={[3, 3, 1.5]} 
									justifyContent="space-around" bg={{
										linearGradient: {
											colors: ['#1ECDFE', '#34F5C6'],
											start: [0, 1],
											end: [1, 0]
										}
									}}
								>
									<Stack space="2">
										<Heading size="md" color="white">
											{project.title}
										</Heading>
										<Text fontSize="xs" color="white" fontWeight="500" mt="-1">
											Inventors: {project.inventors?.join(", ")}
										</Text>
									</Stack>
									<Text fontWeight="400" color="white">
										{project.description}
									</Text>
									<HStack alignItems="center" space="4" justifyContent="space-between">
										<Text color="coolGray.600" _dark={{
										color: "warmGray.200"
									}} fontWeight="400">
											6 mins ago
										</Text>
									</HStack>
								</Stack>
							</Stack>
						</Card>
					</TouchableOpacity>
				))}
			</Stack>	
		</ScrollView>
	);
};
