import React from 'react';
import { Radio } from 'native-base';

function CustomRadioGroup({field, form, options, ...props}) {

  return (
    <Radio.Group 
      name={field.name} 
      value={field.value} 
      onChange={(nextValue) => form.setFieldValue(field.name, nextValue)} 
      accessibilityLabel="Select if company"
      {...props} 
    >
      {options?.map((option, index) => (
        <Radio 
          key={index} 
          value={option.value} 
          my={2}
          colorScheme={'blue'}

        >
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
}

export default CustomRadioGroup;