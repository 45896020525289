import React from 'react';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { useTheme } from 'native-base';

//navigator.geolocation = require("expo-location");

function CustomInputAddress({field, form, hide, ...props}) {

  const { colors } = useTheme();

  return (
    (!hide || (form.values[hide?.field] !== hide?.value)) &&
      <GooglePlacesAutocomplete
        placeholder='Address'
        onPress={(data, details = null) => {
          // 'details' is provided when fetchDetails = true
          console.log(data, details);
          form.setFieldValue(field.name, details?.description)
        }}
        query={{
          key: 'AIzaSyDTKkrbNhjSjW6zuF5fdtT0ikA1VRlwwxs',
          language: 'en',
        }}
        //currentLocation={true}
        //currentLocationLabel='Current location'
        requestUrl={{
          useOnPlatform: 'web', // or "all"
          url:
            'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
          /* headers: {
            Authorization: `an auth token`, // if required for your proxy
          }, */
        }}
        textInputProps={{
          placeholderTextColor: '#a3a3a3',
        }}
        styles={{
          textInputContainer: {
            
          },
          textInput: {
            //height: 36,
            color: '#171717',
            fontSize: 16,
            borderWidth: 1,
            borderColor: colors.coolGray['300'],
            borderRadius: 9999,
            fontFamily: 'Quicksand_400Regular',
            paddingHorizontal: 16,
            paddingVertical: 8
          }
        }}
        {...props}
      />
  );
}

export default CustomInputAddress;