/**
 * Configures the font using expo-font
 * https://fonts.google.com/specimen/quicksand?query=nuni
 */
export const fontConfig = {
    Quicksand: {
        300: {
            normal: 'Quicksand_300Light',
        },
        400: {
            normal: 'Quicksand_400Regular',
        },
        500: {
            normal: 'Quicksand_500Medium',
        },
        600: {
            normal: 'Quicksand_600SemiBold',
        },
        700: {
            normal: 'Quicksand_700Bold',
        },
    },
};
