import React, { useState } from 'react';
import * as DocumentPicker from 'expo-document-picker';
import Button from 'src/components/button';
import { Icon, Text, Box, View } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { storage } from 'src/firebase/config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { useAppSelector } from 'src/ducks/useful-hooks';

const FilePicker = ({field, form, project}) => {

  const user = useAppSelector((state) => state.user);
  const [progress, setProgress] = useState(0)

  const pickDocument = async () => {
    
    try {
      /* const result = await DocumentPicker.pick({
        type: [DocumentPicker.types.allFiles],
        copyTo: 'documentDirectory',
        mode: 'import',
        allowMultiSelection: true,
      }); */
      const result = await DocumentPicker.getDocumentAsync({});
      if (!result) {
        console.log('File not found');
        return;
      }

      //Reset field if new file chosen
      form.setFieldValue(field.name, {})

      const file = result.assets[0];

      //file.fileType = file.mimeType.split('/').pop();
      const _file = await fetch(file.uri);
      const blobFile = await _file.blob();
      const storageRef = ref(storage, `projects/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, blobFile)
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(Math.round(progress))
      }, (error) => {
        console.log(error)
      }, async () => {
        file.url = await getDownloadURL(uploadTask.snapshot.ref)
        form.setFieldValue(field.name, {name: file.name, url: file.url, userID: user.id})
      })

      console.log(file);

      /* if (result.mimeType == 'image/png' || result.mimeType == 'image/jpg' || result.mimeType == 'image/jpeg') {
        setImagePath(result.uri);
        setIsAttachImage(true);
      } else {
        setFilePath(result.uri);
        setIsAttachFile(true);
      } */
      //formikRef.current?.setFieldValue('descriptionFile', result)
      //console.log(formikRef.current?.values)
    } catch (err) {
      console.log(err)
      /* if (DocumentPicker.isCancel(err)) {
        console.log('User cancelled file picker');
      } else {
        console.log('DocumentPicker err => ', err);
        throw err;
      } */
    }
  };

  return (
    <Box flexDirection={'row'} alignItems="center">
      <Button 
        onPress={() => pickDocument()}
        size="sm"
        leftIcon={<Icon as={FontAwesome} name="paperclip" size="sm" />}
        mr={2}
      >
        Choose File
      </Button>
      {(progress === 0 || (progress >= 100 && field.value?.name)) 
        ? <Text>{field.value?.name}</Text>
        : <AnimatedCircularProgress
            size={34}
            //backgroundWidth={30}
            width={4}
            fill={progress}
            tintColor="#0AFEF5"
            tintColorSecondary="#B126BB"
            backgroundColor="#05255C"
          >
            {/* {
              (fill) => (
                <Text style={{fontSize: 8, fontWeight: 600}}>{fill}%</Text>
              )
            } */}
          </AnimatedCircularProgress>
      }
    </Box>
  );
}

export default FilePicker;
