import * as React from 'react';
import { Image } from 'native-base';
import { InterfaceImageProps } from 'native-base/lib/typescript/components/primitives/Image/types';

export /**
 * Main logo icon for the app
 * Created custom icon: https://docs.nativebase.io/icon
 * @param {InterfaceIconProps} props
 * @return {*}
 */
const LogoIcon: React.FC<InterfaceImageProps> = ({ ...props }) => {
	return <Image source={require('assets/icon.png')} {...props} />
};


