import React, { useEffect, useState, useRef } from 'react';
import { useWindowDimensions, StyleSheet, TouchableOpacity } from "react-native";
import { useTheme } from '@react-navigation/native';
import { Box, Text, Stack, VStack, HStack, Pressable, useToast, View, Icon, Heading, ScrollView } from 'native-base';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { ErrorMessage, Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useSaveProjectFieldsMutation } from 'src/services/project-api';
import { AlertToast } from 'src/components/alert-toast';
import { useAppSelector } from 'src/ducks/useful-hooks';
import { serverTimestamp } from 'firebase/firestore';
import { Card } from 'src/components/card';
import Input from 'src/components/input';
import InputAddress from 'src/components/input-address';
import RadioGroup from 'src/components/radio-group';
import TextArea from 'src/components/textarea';
import Button from 'src/components/button';
import FilePicker from 'src/components/file-picker';
import { Octicons, FontAwesome } from '@expo/vector-icons';

/**
 * Create screen - where the user will be able create new IP.
 *
 * @return {*}
 */
export const CreateScreen: React.FC<{}> = () => {

  const user = useAppSelector((state) => state.user);
  // project fields mutation
  const [saveProject, { data: project, isLoading: projectLoading, error: projectError }] = useSaveProjectFieldsMutation();
  const [type, setType] = useState('patent');
  const dimensions = useWindowDimensions();
  const isMobile = dimensions.width < 1024;
  const { colors } = useTheme();
  const formikRef = useRef(null);
  const [progressCircleWidth, setProgressCircleWidth] = useState(0);

  // declare toast
  const toast = useToast();

  const handleProjectUpdate = async (values) => {
    const data = await saveProject({ userIDs: [user.id], ...values }).unwrap();
    toast.show({
        placement: 'bottom',
        render: () => (
            <AlertToast
                title={data.id ? 'Project Updated' : 'Error with updating project.'}
                type={data.id ? 'success' : 'danger'}
                message={
                    data.id
                        ? 'Your project has been updated successfully.'
                        : projectError?.message
                }
                toExit={() => toast.close('project-toast')}
            />
        ),
        id: 'project-toast',
    });
  };

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  // Wizard is a single Formik instance whose children are each page of the
  // multi-step form. The form is submitted on each forward transition (can only
  // progress with valid input), whereas a backwards step is allowed with
  // incomplete data. A snapshot of form state is used as initialValues after each
  // transition. Each page has an optional submit handler, and the top-level
  // submit is called when the final page is submitted.
  const Wizard = ({ children, initialValues, onSubmit }) => {
    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children);
    const [snapshot, setSnapshot] = useState(initialValues);

    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const next = values => {
      setSnapshot(values);
      setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const previous = values => {
      setSnapshot(values);
      setStepNumber(Math.max(stepNumber - 1, 0));
    };

    const handleSubmit = async (values, bag) => {
      if (step.props.onSubmit) {
        await step.props.onSubmit(values, bag);
      }
      if (isLastStep) {
        bag.setSubmitting(true)
        return onSubmit(values);
      } else {
        bag.setTouched({});
        next(values);
      }
    };

    return (
      <Stack direction={["column", "column", "row"]}>
        <Box 
          w={["100%", "100%", "2/3"]}
          borderTopLeftRadius={20} 
          borderBottomLeftRadius={20}
          padding={8}
        >
          <Box 
            //flex={[0, 0, 1]}
            //justifyContent={'center'}
            alignItems={'center'} 
            onLayout={(event) => { 
              const {width} = event.nativeEvent.layout;
              setProgressCircleWidth(width)
            }}
          >
            <AnimatedCircularProgress
              //size={Math.round(progressCircleWidth/2)}
              size={Math.round(progressCircleWidth/5)}
              //backgroundWidth={30}
              //width={Math.round(progressCircleWidth/20)}
              width={Math.round(progressCircleWidth/60)}
              fill={(stepNumber / steps.length) * 100}
              tintColor="#0AFEF5"
              tintColorSecondary="#B126BB"
              backgroundColor="#05255C"
            >
              {
                (fill) => (
                  <Text style={{fontSize: Math.round(progressCircleWidth/30), lineHeight: Math.round(progressCircleWidth/30), fontWeight: 800}}>{Math.round(fill)}%</Text>
                )
              }
            </AnimatedCircularProgress>
          </Box>
          <Box
            //flex={[0, 0, 1]}
          >
            <Formik
              initialValues={snapshot}
              onSubmit={handleSubmit}
              validationSchema={step.props.validationSchema}
              //validateOnMount={true}
              innerRef={formikRef}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                isValid,
                touched,
                focused,
                handleBlur,
                isSubmitting,
                setFieldValue
              }) => (
                <Box>
                  {/* <Text>
                    Step {stepNumber + 1} of {totalSteps}
                  </Text> */}
                  {step.props.children[0]}
                  <Box flexDirection={'row'} justifyContent={'center'} mt={16}>
                    {stepNumber > 0 && (
                      <Button 
                        onPress={() => previous(values)}
                        size="lg"
                        flex={1}
                        bgColor="transparent"
                        _text={{
                          color: colors.button
                        }}
                        leftIcon={<Icon as={Octicons} name="arrow-left" size="md" color={colors.button} mt={1}/>}
                      >
                        Back
                      </Button>
                    )}

                      <Button 
                        isDisabled={!isValid || isSubmitting}
                        onPress={handleSubmit}
                        size="lg"
                        flex={1}
                      >
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
        </Box>
        <Box 
          w={["100%", "100%", "1/3"]}
          //backgroundColor={'#1ECDFE'} 
          bg={{
            linearGradient: {
              colors: ['#1ECDFE', '#34F5C6'],
              start: [0, 1],
              end: [1, 0]
            }
          }}
          borderTopRightRadius={[0, 0, 20]} 
          borderBottomLeftRadius={[20, 20, 0]}
          borderBottomRightRadius={20} 
          justifyContent={'center'}
          padding={8}
        >
          {step.props.children[1]}
        </Box>
      </Stack>
    );
  };

  const WizardStep = ({ children }) => children;

  // state and query hooks
  /* const [begID, setBegID] = useState<string | undefined>(undefined);
  const { data = [], isFetching, refetch } = useGetUsersQuery(begID);
  const [users, setUsers] = useState<Array<PublicUserData>>(data);

  useEffect(() => {
      // concat if there are more users, otherwise set equal to data
      if (begID) {
          setUsers(users.concat(data));
      } else {
          setUsers(data);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[0]?.id]);*/

  return (
    <ScrollView
      px={isMobile ? 4 : 16}
      py={isMobile ? 4 : 8}
    >
      <Card
        p={0}
      >
        <Wizard
          initialValues={{
            title: '',
            description: '',
            disclosure: {},
            assets: [],
            features: [],
            inventors: [''],
            isCompany: false,
            companyName: '',
            companyAddress: '',
            deposit: ''
          }}
          onSubmit={async (values) => {
            //sleep(3000).then(() => console.log('Wizard submit', values))
            handleProjectUpdate(values)
          }}
        >
          <WizardStep
            //onSubmit={() => console.log('Step1 onSubmit')}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('required'),
            })}
          >
            <Box style={styles.stepContainer}>
              <Heading style={styles.label}>What is the title of your project?</Heading>
              <Field
                name="title"
                placeholder="Title"
                type="text"
                component={Input}
              />
              {/* <ErrorMessage className="error" name="title" /> */}
            </Box>
            <Box style={styles.stepContainer}>
              <Text style={styles.tip}>Tip: This will NOT be used as the title of your invention. We'll figure that out later.</Text>
              <Text style={styles.tip}>The title should be something memorable.</Text>
            </Box>
          </WizardStep>
          <WizardStep
            /* validationSchema={
              Yup.object().shape({
                description: Yup.string(),
                file: Yup.string(),
              })
              .test(
                'description or file',
                'Description or File is required',
                (value) => has(value, 'description') || has(value, 'file')
              )
            } */
            validationSchema={                                                                                         
              Yup.object().shape({                                                                                 
                'description': Yup.string()                                                                        
                  .when('disclosure', {                                                    
                    is: (disclosure) => !disclosure || Object.keys(disclosure).length === 0,                      
                    then: Yup.string()                                                   
                    .required('At least one of the fields is required'),                 
                  }),                                                                  
                'disclosure': Yup.object()                                                                       
                  .when('description', {                                                     
                    is: (description) => !description || description.length === 0,                         
                    then: Yup.object()
                    .required('At least one of the fields is required')
                  })                                                                   
              }, ['description', 'disclosure'])                                                                              
          }         
          >
            <Box style={styles.stepContainer}>
              <Heading style={styles.label}>Please provide a description of the invention or upload a previous disclosure.</Heading>
              <Field
                name="description"
                placeholder="Description"
                component={TextArea}
              />
              <Box flexDirection={'row'} justifyContent={'center'} alignItems={'center'} my={4}>
                <Box height={'1px'} backgroundColor={'coolGray.400'} width={20} />
                <Text color={'coolGray.400'} mx={2}>or</Text>
                <Box height={'1px'} backgroundColor={'coolGray.400'} width={20} />
              </Box>
              <Box alignItems={'center'}>
                <Field 
                  name="disclosure" 
                  component={FilePicker} 
                />
              </Box>
              {/* <ErrorMessage className="error" name="title" /> */}
            </Box>
            <Box style={styles.stepContainer}>
              <Text style={styles.tip}>Tip: Provide a description of what your patent is about.</Text>
            </Box>
          </WizardStep>
          <WizardStep
            validationSchema={Yup.object().shape({
              //assets: Yup.array().required('required'),
            })}
          >
            <Box style={styles.stepContainer}>
              <Heading style={styles.label}>Do you have sketches, cad files, pictures, etc. of the invention to upload?</Heading>
              <FieldArray
                name="assets"
                render={arrayHelpers => (
                  <Box>
                    {formikRef.current?.values?.assets.map((asset, index) => (
                      <Box key={index} flexDirection={'row'} alignItems={'center'} mb={2}>
                        <Field 
                          name={`assets[${index}]`}
                          component={FilePicker} 
                        />
                        <Button
                          size="sm"
                          backgroundColor={'transparent'}
                          onPress={() => arrayHelpers.remove(index)}
                          marginLeft={1}
                          _text={{color: 'black', fontWeight: 600}}
                        >
                          X
                        </Button>
                      </Box>
                    ))}
                    <Box alignItems={'center'} mt={4}>
                      <Button
                        size="sm"
                        onPress={() => arrayHelpers.push('')}
                        style={{width: 80}}
                      >
                        Add +
                      </Button>
                    </Box>
                  </Box>
                )}
              />
              {/* <ErrorMessage className="error" name="title" /> */}
            </Box>
            <Box style={styles.stepContainer}>
              <Text style={styles.tip}>Tip: These are any assets you might have related to your invention.</Text>
            </Box>
          </WizardStep>
          <WizardStep
            validationSchema={Yup.object().shape({
              //features: Yup.string().required('required'),
            })}
          >
            <Box style={styles.stepContainer}>
              <Heading style={styles.label}>If known, please provide key features or what you feel is the most important/unique aspect of the invention.</Heading>
              <Field
                name="features"
                placeholder="Features"
                component={TextArea}
              />
              {/* <ErrorMessage className="error" name="title" /> */}
            </Box>
            <Box style={styles.stepContainer}>
              <Text style={styles.tip}>Example: This is the fastest car ever created.</Text>
            </Box>
          </WizardStep>
          <WizardStep
            validationSchema={Yup.object().shape({
              inventors: Yup.array().min(1, 'At least 1 inventor is required.').required('required'),
            })}
          >
            <Box style={styles.stepContainer}>
              <Heading style={styles.label}>List the inventor(s).</Heading>
              <FieldArray
                name="inventors"
                render={arrayHelpers => (
                  <Box>
                    {formikRef.current?.values?.inventors.map((inventor, index) => (
                      <Box key={index} flexDirection={'row'} alignItems={'center'} mb={2}>
                        <Field 
                          name={`inventors[${index}].name`}
                          placeholder="Inventor Name"
                          type="text"
                          component={Input} 
                          flex={1}
                        />
                        <Field 
                          name={`inventors[${index}].address`}
                          placeholder="Inventor Address"
                          type="text"
                          component={Input} 
                          flex={1}
                          marginLeft={2}
                        />
                        <Button
                          size="sm"
                          backgroundColor={'transparent'}
                          onPress={() => arrayHelpers.remove(index)}
                          marginLeft={1}
                          _text={{color: 'black', fontWeight: 600}}
                        >
                          X
                        </Button>
                      </Box>
                    ))}
                    <Box alignItems={'center'} mt={4}>
                      <Button
                        size="sm"
                        onPress={() => arrayHelpers.push('')}
                        style={{width: 80}}
                      >
                        Add +
                      </Button>
                    </Box>
                  </Box>
                )}
              />
              {/* <ErrorMessage className="error" name="title" /> */}
            </Box>
            <Box style={styles.stepContainer}>
              <Text style={styles.tip}>Tip: You can add or remove them at any time.</Text>
            </Box>
          </WizardStep>
          <WizardStep
            validationSchema={Yup.object().shape({
              isCompany: Yup.boolean().required('required'),
              companyName: Yup.string()
                .when('isCompany', {
                  is: true,
                  then: Yup.string().required('required')
                }),
              companyAddress: Yup.string()
                .when('isCompany', {
                  is: true,
                  then: Yup.string().required('required')
                }),
            })}
          >
            <Box style={styles.stepContainer}>
              <Heading style={styles.label}>Is the patent being filed by a company?</Heading>
              <Field
                name="isCompany"
                component={RadioGroup}
                options={[
                  {label: 'No', value: false},
                  {label: 'Yes', value: true}
                ]}
              />
              <Box mt={3}>
                <Field 
                  name="companyName"
                  placeholder="Company Name"
                  type="text"
                  component={Input} 
                  hide={{field: 'isCompany', value: false}}
                  mb={2}
                />
                <Field 
                  name="companyAddress"
                  placeholder="Company Address"
                  component={InputAddress} 
                  hide={{field: 'isCompany', value: false}}
                  flex={1}
                />
              </Box>
              {/* <ErrorMessage className="error" name="title" /> */}
            </Box>
            <Box style={styles.stepContainer}>
              <Text style={styles.tip}>Tip: This can be changed at a later time.</Text>
            </Box>
          </WizardStep>
          <WizardStep
            validationSchema={Yup.object().shape({
              deposit: Yup.string().required('required'),
            })}
          >
            <Box style={styles.stepContainer}>
              <Heading style={styles.label}>Deposit Information</Heading>
              {/* <Text style={styles.tip}>Tip: You can add or remove them at any time.</Text> */}
              <Field
                name="deposit"
                placeholder="Deposit"
                component={Input}
              />
              {/* <ErrorMessage className="error" name="title" /> */}
            </Box>
            <Box style={styles.stepContainer}>
              <Text style={styles.tip}>There are multiple payment plan options!</Text>
            </Box>
          </WizardStep>
        </Wizard>
      </Card>
    </ScrollView>
  );

};

const styles = StyleSheet.create({
  stepContainer: {
    //flex: 1,
    justifyContent: 'center',
    marginVertical: 32,
  },
  label: {
    fontSize: 20,
    marginBottom: 16
  },
  tip: {
    color: '#FFF',
    fontSize: 16,
    //textAlign: 'center',
    lineHeight: 20,
    marginBottom: 16,
    fontStyle: 'italic',
    fontFamily: 'Quicksand_500Medium'
  }
})