import { getProjectData, getProjects, saveProjectData } from 'src/firebase/project-api';
import { ProjectData } from 'src/types';
import { ConfigApi } from './config-api';

/**
 * Projects api for fetching data related to projects
 *
 * @resources
 * Customizing RTK Query with following resources
 * Firebase api calls with RTK-Query: https://stackoverflow.com/questions/71587312/is-it-possible-to-use-firebase-query-with-redux-toolkit-or-rtk-query-in-react)
 * Using queryfn: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#implementing-a-queryfn
 * Using fakeBaseQuery: https://redux-toolkit.js.org/rtk-query/usage-with-typescript#typing-a-queryfn
 * Code Splitting: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
 *
 */
export const ProjectApi = ConfigApi.injectEndpoints({
	endpoints: (build) => ({
		getProjects: build.query<Array<ProjectData>, string | undefined>({
		/**
		 * Generating query for fetching and paginating projects
		 * @param {*} begID
		 * @return {*}
		 */
			async queryFn(begID) {
				try {
					const querySnapshot = await getProjects(begID, 3, 'desc');
					const projects = querySnapshot.docs.map((projectDoc) => projectDoc.data());
					return { data: projects };
				} catch (e: any) {
					console.warn(`Error with fetching projects`);
					return { error: e };
				}
			},
		}),
		saveProjectFields: build.mutation<
			ProjectData,
			{ id: string } & Partial<Omit<ProjectData, 'email'>>
		>({
			/**
			 * Sets the project fields in firebase, gets the project data to return
			 *
			 * @param {*} projectFields
			 * @return {*}
			 */
			async queryFn(projectFields) {
				try {
					await saveProjectData(projectFields);
					const snapshot = await getProjectData(projectFields.id);
					return { data: snapshot.data() };
				} catch (e: any) {
					console.warn(`Error with updating project fields: ${e}`);
					return { error: e };
				}
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetProjectsQuery, useSaveProjectFieldsMutation } = ProjectApi;
