import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
    ProjectsScreen,
    ProjectScreen,
    ProjectSettingsScreen
} from 'src/screens';
import { Icon, IconButton } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

export type ProjectsStackParams = {
    Projects: undefined;
    Project: {
      project: {
        id: string,
        title: string
      }
    },
    ProjectSettings: undefined;
};

const ProjectsNav = createNativeStackNavigator<ProjectsStackParams>();

export /**
 * Projects Stack, used for navigating between projects and password screen
 *
 * @return {*}
 */
const ProjectsStack: React.FC<{}> = ({navigation}) => {
  return (
    <ProjectsNav.Navigator>
      <ProjectsNav.Screen
        name="Projects"
        component={ProjectsScreen}
        options={{ headerShown: false }}
      />
      <ProjectsNav.Screen
        name="Project"
        component={ProjectScreen}
        options={({ route }) => ({ 
          title: route.params.project.title, 
          headerRight: () => 
            <IconButton
              alignSelf="flex-end"
              variant="unstyled"
              icon={<Icon as={MaterialIcons} name="settings" size="lg" color="primary.700" />}
              onPress={() => navigation.navigate('ProjectSettings', {project: route.params.project})}
            />,
          //presentation: 'modal',
          headerTitleStyle: {
            fontFamily: 'Quicksand_600SemiBold',
          }
        })}
      />
      <ProjectsNav.Screen
        name="ProjectSettings"
        component={ProjectSettingsScreen}
        options={({ route }) => ({ 
          title: 'Settings', 
        })}
      />
    </ProjectsNav.Navigator>
  );
};
