import React from 'react';
import { Keyboard, Platform } from 'react-native';
import {
	Box,
	VStack,
	Heading,
	Text,
	useToast,
	HStack,
	KeyboardAvoidingView,
	Center
} from 'native-base';
import Button from 'src/components/button';
import { useNavigation, useTheme } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailSchema } from 'src/utils/schemas';
import { FormInput } from 'src/components/form-input';
import { useAppSelector } from 'src/ducks/useful-hooks';
import { AuthStackParams } from 'src/navigation/auth-stack';
import { AlertToast } from 'src/components/alert-toast';
import {
    useLazyFetchSignInMethodsQuery,
    useLazySignOutQuery,
    useLazySignUpQuery,
} from 'src/services/auth-api';
import { LogoIcon } from 'src/components/logo-icon';
import { Card } from 'src/components/card';

// define navigation props
type WelcomeScreenParams = StackNavigationProp<AuthStackParams, 'Welcome'>;

export /**
 * Welcome Screen, used either signing into or making an account
 *
 * @return {*}
 */
const WelcomeScreen: React.FC<{}> = () => {
    // hooks
    const navigation = useNavigation<WelcomeScreenParams>();
    const isAnonymous = useAppSelector((state) => state.user.isAnonymous);
    const toast = useToast();
    const iconColor = useTheme().colors.text;

    // form schema hooks
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(emailSchema),
    });

    // redux query hooks
    const [getSignInMethods, { isFetching: emailFetching, error: fetchingError }] =
        useLazyFetchSignInMethodsQuery();
    const [triggerSignUp, { isFetching: guestFetching }] = useLazySignUpQuery();
    const [triggerSignOut] = useLazySignOutQuery();

    // toast component for guest
    /* const renderGuestToast = () => (
        <AlertToast
            title="Using Maet as a Guest."
            type="primary"
            toExit={() => toast.close('guestToast')}
        />
    ); */

    // handling button presses
    const handleEmail = async (data: any) => {
        // updating variables within function, since they need to be sent to new screen
        const emailSignInMethods = await getSignInMethods(data.email).unwrap();
        navigation.navigate('Login', {
            signInMethods: emailSignInMethods,
            email: data.email,
        });
        reset();
    };

    const handleAnonymous = async () => {
        await triggerSignUp('guest');
        toast.show({
            placement: 'top',
            render: renderGuestToast,
            id: 'guestToast',
        });
    };

    return (
        <KeyboardAvoidingView
					/* h={{
							lg: 'auto',
					}} */
					behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
					onTouchStart={() => Keyboard.dismiss()}
					w="100%"
					h="100%"
				>
					<Center
						flex={1}
						px="10"
					>
						<Card px={8} py={12} width={'100%'} alignItems={'center'} justifyContent={'center'} maxWidth={600}>
							<VStack space={3} w="100%" maxWidth={400}>
								<VStack pt={5} space={5} w="100%" justifyContent="center" alignItems={'center'}>
									<LogoIcon size={'xl'} color={iconColor} />
									<Heading mb={10} fontWeight={100}>
										Let's get started.
									</Heading>
								</VStack>
								<FormInput
									key="login-email"
									name="email"
									control={control}
									isInvalid={'email' in errors}
									label="Email"
									placeholder="name@example.com"
									defaultValue=""
									errorMessage={String(errors?.email?.message)}
									mb={3}
								/>
								{/* <Button mt="3" colorScheme="primary" w="100%" >
										Send me a sign-in link
								</Button> */}
								<Button
									key="Password-Button"
									w="100%"
									onPress={handleSubmit(handleEmail)}
									isLoading={emailFetching}
									isLoadingText="Submitting"
									maxWidth={400}
								>
										Submit
								</Button>
							</VStack>
						</Card>
					</Center>
        </KeyboardAvoidingView>
    );
};
