import React, { memo } from 'react';
import { Avatar, Box, HStack, Text } from 'native-base';
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box';

/**
 * Params extended from InterfaceBoxProps
 *
 * @export
 * @interface CardParams
 * @extends {InterfaceBoxProps}
 */
export interface CardParams extends InterfaceBoxProps {
    /* firstName?: string | null;
    lastName?: string | null;
    count?: number | null;
    image?: string; */
}

/**
 * Card component, exported using memoized component
 * https://blog.logrocket.com/what-are-react-pure-functional-components/
 * @param {*} {
 *     firstName,
 *     lastName,
 *     count,
 *     image,
 * 		 children
 *     ...boxParams
 * }
 * @return {*}
 */
const CardComp: React.FC<CardParams> = ({
	children,
	...boxParams
}) => {
	return (
		<Box
			p={4}
			//flex={1}
			bgColor="white"
			borderRadius={20}
			borderColor={'#E5E5E5'}
			borderWidth={2}
			{...boxParams}
		>
			{children}
		</Box>
	);
};

CardComp.defaultProps = {
    /* firstName: undefined,
    lastName: undefined,
    count: undefined,
    image: undefined, */
};

/**
 * Card component, exported using memoized component
 * https://blog.logrocket.com/what-are-react-pure-functional-components/
 * @param {*} {
 *     firstName - string,
 *     lastName - string,
 *     count - number,
 *     image - string uri,
 * 		 children
 *     ...boxParams
 * }
 * @return {*}
 */
export const Card = memo(CardComp);
