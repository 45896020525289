/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Box, FormControl, KeyboardAvoidingView, useToast, Icon } from 'native-base';
import { FormInput } from 'src/components/form-input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editProfileSchema, EditProfileSchemaType } from 'src/utils/schemas';
import { useSaveProjectFieldsMutation } from 'src/services/project-api';
import { useAppSelector } from 'src/ducks/useful-hooks';
import { Keyboard, Platform } from 'react-native';
import { AlertToast } from 'src/components/alert-toast';
import { SettingStackParams } from 'src/navigation/settings-stack';
import { StackScreenProps } from '@react-navigation/stack';
import { MaterialIcons } from '@expo/vector-icons';

type ProjectSettingsScreenProps = StackScreenProps<SettingStackParams, 'ProjectSettings'>;

export /**
 * Project Settings Screen, used for allowing projects to change their information
 *
 * @param {*} { navigation }
 * @return {*}
 */
const ProjectSettingsScreen: React.FC<ProjectSettingsScreenProps> = ({ navigation }) => {

		const initialProjectData = useAppSelector((state) => state.project);

    // project image fields mutation
    const [saveProject, { data: project = initialProjectData, isLoading: projectLoading, error: projectError }] = useSaveProjectFieldsMutation();


    // declare toast
    const toast = useToast();

    // For more items that be destructured  https://react-hook-form.com/api/useform
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<EditProfileSchemaType>({
        resolver: yupResolver(editProfileSchema),
    });

    const handleProjectUpdate = async ({ title, description }: EditProfileSchemaType) => {
        const data = await saveProject({ id: initialProjectData.id, title, description }).unwrap();
        toast.show({
            placement: 'bottom',
            render: () => (
                <AlertToast
                    title={data.id ? 'Project Settings Updated' : 'Error with updating project settings.'}
                    type={data.id ? 'success' : 'danger'}
                    message={
                        data.id
                            ? 'Your project settings have been updated successfully.'
                            : projectError?.message
                    }
                    toExit={() => toast.close('project-toast')}
                />
            ),
            id: 'project-toast',
        });
    };

    return (
        <KeyboardAvoidingView
            h={{
                lg: 'auto',
            }}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            onTouchStart={() => Keyboard.dismiss()}
            w="100%">
            <Box px={5}>
                <FormControl>
                    <FormInput
                        key="title"
                        name="title"
                        control={control}
                        isInvalid={'title' in errors}
                        label="Project Title"
                        placeholder="Title"
                        defaultValue={project.title ? project.title : ''}
                        errorMessage={errors?.title?.message}
                    />
                    <FormInput
                        key="description"
                        name="description"
                        control={control}
                        isInvalid={'description' in errors}
                        label="Project Description"
                        placeholder="Description"
                        defaultValue={project.description ? project.description : ''}
                        errorMessage={errors?.description?.message}
                    />
                    <Button
                        isLoading={isSubmitting || projectLoading}
                        isDisabled={!isDirty}
                        mt={8}
                        my={3}
                        onPress={handleSubmit(handleProjectUpdate)}>
                        Save Changes
                    </Button>
                </FormControl>
                <Button
                    variant="subtle"
                    my={2}
                    endIcon={<Icon as={MaterialIcons} name="arrow-forward-ios" />}
                    onPress={() => navigation.navigate('Password')}>
                    Change Password
                </Button>
            </Box>
        </KeyboardAvoidingView>
    );
};
